import { useQuery } from '@tanstack/vue-query';
import { productsService } from '~/utils/api/productsService';

export function useProducts() {
  const { data, isLoading } = useQuery({
    queryKey: ['products'],
    queryFn: productsService.getProducts,
  });

  const membershipSubscriptionPrice = computed(() => {
    if (!data.value) return null;

    const subscriptionProduct = data.value
      .filter((product) => !product.is_archived)
      .find((product) =>
        product.prices.find((price) => price.type === 'recurring'),
      );

    if (!subscriptionProduct) {
      throw new Error('No subscription product found');
    }

    const price = subscriptionProduct.prices.find(
      (price) => price.type === 'recurring',
    );

    if (!price) {
      throw new Error('No default recurring price found');
    }

    return price;
  });

  return {
    data,
    membershipSubscriptionPrice,
    isLoading,
  };
}
