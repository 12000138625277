import { fetcher } from '../fetcher';
import * as Sentry from '@sentry/vue';

const CARDS_ENDPOINT = '/products';

const { STRIPE_URL } = useRuntimeConfig().public;

if (!STRIPE_URL) {
  Sentry.captureException(
    new ResponseError('STRIPE_URL environment variable is not set', 111),
  );

  showError({
    message:
      'Coś poszło nie tak przy pobieraniu danych. Spróbuj ponownie później.',
  });
}

export const productsService = {
  getProducts: async () => {
    return await fetcher(`${CARDS_ENDPOINT}`, {
      method: 'GET',
      customApiUrl: `${STRIPE_URL}`,
      schema: productsSchema,
    });
  },
};
